<template>
    <v-app>
        <v-app-bar app elevation="0" outlined>
            <img :src="require('@/assets/jl_logo.png')" alt="Company Logo" class="logo"/>
            <v-tabs centered>
                <v-tab @click="tabClick('aboutSection')">关于</v-tab>
                <v-tab @click="tabClick('solutionsSection')">解决方案</v-tab>
                <v-tab @click="tabClick('taSection')">治疗领域</v-tab>
                <v-tab @click="tabClick('contactSection')">联系我们</v-tab>
            </v-tabs>
        </v-app-bar>
        <v-main class="grey--text text--darken-2">
            <v-container class="pa-0">
                <section id="about" ref="aboutSection" class="section intro-section grey--text text--darken-2 d-flex align-center">
                    <v-container>
                        <v-row class="d-flex align-center">
                            <v-col cols="6" class="">
                                <h2 class="section-header">
                                    关于
                                </h2>
                                <br>
                                <p class="section-content">
                                【橘络科技】借助丰富的行业经验和专业技术，致力于为临床试验运营提供高质量、高效率和更加安全的数据管理与医学核查方案。
                                </p>
                            </v-col>
                            <v-col cols="6">
                                <v-img
                                        rounded
                                        :src="require('@/assets/jl_back.png')"
                                        height="350"
                                        style="opacity: 0.5;"
                                        ></v-img>
                            </v-col>
                        </v-row>
                    </v-container>
                </section>

                <section id="solutions" ref="solutionsSection" class="section product-section d-flex align-center">
                    <v-container>
                        <v-row>
                            <v-col cols="6" align-self-start class="pl-0">
                                <v-card elevation="0">
                                    <v-img
                                            rounded
                                            :src="require('@/assets/prod_section2.jpg')"
                                            max-height="350"
                                            style="opacity: 1;"
                                            ></v-img>
                                </v-card>
                            </v-col>
                            <v-col cols="6" class="grey--text text--darken-2 d-flex align-center pl-5 pr-0">
                                <span>
                                    <h2 class="section-header">解决方案</h2>
                                    <br>
                                    <p class="section-content">
                                    <span class="primary--text text--darken-1 font-weight-medium">CITRUS</span>  是一款中心化数据与医学核查平台，为临床试验项目提供强大的数据分析和可视化功能，显著提高制药企业在临床试验数据和医学核查方面的效率，整体把控监控项目的运营风险，降低受试者的风险，并提高数据的质量和可靠性。
                                    </p>
                                </span>
                            </v-col>
                        </v-row>
                    </v-container>
                </section>

                <section id="ta" ref="taSection" class="section ta-section d-flex align-center">
                    <v-container>
                        <v-row>
                            <v-col cols="6" class="d-flex align-center justify-space-around">
                                <h2 class="section-header">治疗领域实践</h2>
                            </v-col>
                            <v-col>
                                <v-card class="ma-0" elevation=0 color="transparent" height="350">
                                    <v-card-text>
                                        <ul>
                                            <li>
                                                <h3>内分泌 - 糖尿病</h3>
                                                <ul>
                                                    <li>成熟的实时滴定(titration mornitoring and review)解决方案</li>
                                                    <li>10+全球diabetes项目</li>
                                                    <li>基于历史数据的中心优化方案与入组决策</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>内分泌 - 孕激素</h3>
                                                <ul>
                                                    <li>高度订制的医学核查解决方案</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>多发性硬化</h3>
                                                <ul>
                                                    <li>4+全球项目</li>
                                                    <li>多维度数据分析</li>
                                                    <li>中心化数据核查与数据核查管理</li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h3>肿瘤</h3>
                                                <ul>
                                                    <li>2+项目</li>
                                                    <li>高度定制化Patient Profile (基于cycle)</li>
                                                    <li>数据核查质量分析</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </section>

                <section id="contact" ref="contactSection" class="contact-section">
                    <v-container>
                        <h2 class="section-header text-subtitle-1">联系我们</h2>
                        <p class="section-content text-subtitle-2">Email: info@juluotech.cn<br></p>
                    </v-container>
                </section>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    export default {
        metaInfo: {
            title: 'JuluoTech',
            titleTemplate: '%s',
            htmlAttrs: {}
        },
        methods: {
            tabClick(tab) {
                const targetSection = this.$refs[tab];
                if (targetSection) {
                    targetSection.scrollIntoView({ behavior: 'smooth' });
                    targetSection.style.scrollMarginTop = '90px'; // Replace '64px' with the height of your navbar
                }
            },
        },
        mounted() {
        }
    };
</script>

<style>
/* Styles for the navbar */
.logo {
    height: 28px;
    margin-right: 10px;
}

.v-tabs__div {
    background-color: #ffffff;
}

.v-tabs__slider {
    background-color: #39b54a !important;
}

.v-tabs__item {
    color: #000000 !important;
}

.v-tab--active {
    font-weight: bold;
}

/* Rest of the styles remain the same */
.intro-section {
    background-color: #f9f9f9;
}

.product-section {
    background-color: #ffffff;
}

.team-section {
    background-color: #ffffff;
}

.ta-section {
    background-color: #f9f9f9;
}

.contact-section {
    background-color: #ffffff;
}

.section {
    height: 350px;
    width: 100%;
    margin-bottom: 40px;
}

.section-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.section-content {
    font-size: 20px;
}

.footer {
    background-color: #f7f7f7;
    padding: 20px;
    text-align: center;
}

.footer-text {
    font-size: 14px;
}
</style>
